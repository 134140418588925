import { IconButton, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Delete } from "@mui/icons-material";
import { format } from "date-fns";

const InputRow = ({ field, register, index, errors, remove }) => {
  const { t } = useTranslation("common");
  return (
    <Box sx={{ display: "flex", mb: 2 }}>
      <TextField
        sx={{ marginRight: "5px", width: "360px" }}
        label={t("event")}
        color="primary"
        focused
        required
        error={
          errors?.slip && errors.slip[index] && !!errors.slip[index].eventName
        }
        {...register(`slip.${index}.eventName`, { required: true })}
      />
      <TextField
        sx={{ marginRight: "5px", width: "130px" }}
        label={t("prediction")}
        color="primary"
        focused
        required
        error={
          errors?.slip && errors.slip[index] && !!errors.slip[index].prediction
        }
        {...register(`slip.${index}.prediction`, { required: true })}
      />
      <TextField
        sx={{ marginRight: "5px", width: "110px" }}
        label={t("odd")}
        color="primary"
        type="number"
        required
        focused
        error={errors?.slip && errors.slip[index] && !!errors.slip[index].odd}
        {...register(`slip.${index}.odd`, { required: true })}
      />
      <TextField
        sx={{ marginRight: "5px", width: "140px" }}
        label={t("date")}
        type="date"
        color="primary"
        required
        focused
        defaultValue={format(new Date(), "yyyy-MM-dd")}
        error={errors?.slip && errors.slip[index] && !!errors.slip[index].date}
        {...register(`slip.${index}.date`, { required: true })}
      />
      {index !== 0 && (
        <Box>
          <IconButton
            color="error"
            component="span"
            onClick={() => remove(index)}
            sx={{ position: "absolute", zIndex: 99 }}
          >
            <Delete
              sx={{
                fontSize: "32px",
                color: (theme) => theme.palette.error.main,
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default InputRow;
