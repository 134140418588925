import { round } from "lodash";
const roundBets = (bets, stake) => {
  const roundedBets = bets.map((bet) => Math.floor(bet));
  const remainingStake = stake - roundedBets.reduce((a, b) => a + b, 0);
  const decimalBets = bets.map((bet) => bet - Math.floor(bet));
  const sortedIndexes = decimalBets
    .map((decimalBet, index) => [decimalBet, index])
    .sort(([a], [b]) => b - a)
    .map(([, index]) => index);

  for (let i = 0; i < remainingStake; i++) {
    const index = sortedIndexes[i % sortedIndexes.length];
    roundedBets[index]++;
  }

  return roundedBets;
};
export function calculateArbitrage(odds, stake) {
  const reciprocalOdds = odds.map((odds) => round(1 / odds, 2));
  const totalReciprocalOdds = reciprocalOdds.reduce((a, b) => a + b);

  const bets = reciprocalOdds.map(
    (odds) => (stake * odds) / totalReciprocalOdds
  );
  const totalStake = bets.reduce((a, b) => a + b);
  let betSum = 0;

  for (let i = 0; i < bets.length; i++) {
    betSum += bets[i] * odds[i];
  }

  const profit = round(betSum / bets.length, 2) - stake;
  const profitPct = ((profit / stake) * 100).toFixed(2) + "%";
  const newStakes = [];
  let newStake = stake;
  let newBets = reciprocalOdds.map((odds) =>
    round((newStake * odds) / totalReciprocalOdds, 2)
  );
  const newData = [];
  const initialBets = newBets;

  while (newStake <= stake * 2) {
    const hasDecimals = newBets.some((bet) => bet % 1 !== 0);
    if (!hasDecimals) {
      // eslint-disable-next-line no-loop-func
      const bet = reciprocalOdds.map((odds) =>
        round((newStake * odds) / totalReciprocalOdds, 2)
      );
      newStakes.push(newStake);
      newData.push({ stake: newStake, bets: bet });
    }
    newStake += 1;
    // eslint-disable-next-line no-loop-func
    newBets = reciprocalOdds.map((odds) =>
      round((newStake * odds) / totalReciprocalOdds, 2)
    );
  }

  const roundBets = bets.map((bet) => Math.floor(bet));
  const roundTotalStake = roundBets.reduce((a, b) => a + b);
  const roundProfit = roundTotalStake - stake;
  const roundProfitPct = ((roundProfit / stake) * 100).toFixed(2) + "%";

  if (newStakes.length > 0) {
    return {
      bets: bets.map((bet) => bet.toFixed(2)),
      totalStake: totalStake.toFixed(2),
      profit: profit.toFixed(2),
      profitPct: profitPct,
      newStakes: newStakes,
      newBets: newBets.map((bet) => bet.toFixed(2)),
      newData,
      initialBets,
    };
  } else {
    return {
      bets: bets.map((bet) => bet.toFixed(2)),
      totalStake: totalStake.toFixed(2),
      profit: profit.toFixed(2),
      profitPct: profitPct,
      initialBets,
    };
  }
}

export default calculateArbitrage;
