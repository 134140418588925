export const renderProfit = ({ stake, odds, status }) => {
  switch (status) {
    case "PENDING":
      return stake;
    case "LOST":
      return 0;
    case "VOID":
      return stake;
    case "WON":
      return odds * stake;
    case "HALF_WON":
      const halfProfit = (odds * stake - stake) / 2;
      return +stake + halfProfit;
    case "HALF_LOST":
      return stake / 2;
    default:
      break;
  }
};

export default renderProfit;
