import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const AreYouSureModal = ({ open, handleClose, handleDelete, modalText }) => {
  const { t } = useTranslation("common");
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("Attention")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {modalText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} autoFocus>
          {t("Continue")}
        </Button>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AreYouSureModal;
