import React, { useState } from "react";

const ImageComponent = ({
  src,
  baseUrl,
  providerName,
  fallbackExt,
  alt,
  ...rest
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    const fallbackSrc = `${baseUrl}${providerName}.${fallbackExt}`;
    setImageSrc(fallbackSrc);
  };

  return <img src={imageSrc} alt={alt} onError={handleImageError} {...rest} />;
};

export default ImageComponent;
