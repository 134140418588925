import { Container, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PillButton } from "../components";
import calculateArbitrage from "../utils/calculatePossibleBets";

const ArbitrageCalculator = () => {
  const [odds, setOdds] = useState({});
  const [stake, setStake] = useState();
  const [possibleBets, setPossibleBets] = useState([]);
  const [profitData, setProfitData] = useState({});
  const [initial, setInitial] = useState([]);

  const { t } = useTranslation("common");

  const handleOdds = (e) => {
    setOdds({ ...odds, [e.target.id]: toNumber(e.target.value) });
  };

  const calculatePossibleBets = () => {
    if (!stake) return alert("Please enter a stake");
    if (!odds.odd1 || !odds.odd2) return alert("Please enter all odds");
    const oddsArray = Object.values(odds);
    const possibleBets = calculateArbitrage(oddsArray, stake);
    const { newData, profit, profitPct, initialBets } = possibleBets;
    initialBets && setInitial(initialBets);
    profit && profitPct && setProfitData({ ...profitData, profit, profitPct });
    newData && setPossibleBets(newData);
  };

  return (
    <Container maxWidth="lg" sx={{ paddingY: "80px" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          pb: 6,
        }}
      >
        {t("arbitrageCalculator")}
      </Typography>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" flexDirection="row">
          <Box
            display="flex"
            justifyContent="space-between"
            width="35%"
            alignItems="center"
          >
            <Box width="50%" display="flex" flexDirection="column">
              <TextField
                color="success"
                margin="normal"
                id="odd1"
                label={`${t("odd")} 1`}
                name="odd1"
                sx={{ maxWidth: "150px" }}
                onChange={handleOdds}
                type="number"
              />
              <TextField
                color="success"
                margin="normal"
                id="odd2"
                label={`${t("odd")} 2`}
                name="odd2"
                sx={{ maxWidth: "150px" }}
                onChange={handleOdds}
                type="number"
              />
              <TextField
                color="success"
                margin="normal"
                id="odd3"
                label={`${t("odd")} 3`}
                name="odd3"
                sx={{ maxWidth: "150px" }}
                onChange={handleOdds}
                type="number"
              />
            </Box>{" "}
            <Box width="50%">
              <TextField
                color="success"
                margin="normal"
                id="stake"
                label={`${t("stake")}`}
                name="stake"
                sx={{ maxWidth: "150px" }}
                onChange={(e) => setStake(toNumber(e.target.value))}
                type="number"
              />
              <Box>
                <Typography
                  sx={{
                    color: (theme) =>
                      profitData.profit > 0
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {profitData.profit && `${t("profit")}:`} {profitData.profit}{" "}
                  {profitData.profit && t("lev")}
                </Typography>
                <Typography
                  sx={{
                    color: (theme) =>
                      profitData.profitPct > 0
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {profitData.profitPct && `${t("profitPct")}:`}{" "}
                  {profitData.profitPct}{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "15%", mx: 2 }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{
                border: "1px solid green",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                height: "100%",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                {t("initialBets")}
              </Typography>
              {initial.map((bet, i) => (
                <Box
                  key={i}
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                  sx={{ mt: 2, borderBottom: "1px solid #444", pb: 2 }}
                >
                  <Box width="50%" display="flex" flexDirection="row">
                    <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>{`${t(
                      "slip"
                    )}[${i + 1}]:`}</Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                        ml: 2,
                        color: "#6DBE71",
                      }}
                    >
                      {bet}
                      {t("lev")}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="45%"
            sx={{
              border: "1px solid green",
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              {t("possibleCombinations")}
            </Typography>
            {possibleBets.map((bet, i) => (
              <Box
                key={i}
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                sx={{ mt: 2, borderBottom: "1px solid #444", pb: 2 }}
              >
                <Box width="50%" display="flex" flexDirection="row">
                  <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>{`${t(
                    "stake"
                  )}[${i + 1}]:`}</Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      ml: 2,
                      color: "#6DBE71",
                    }}
                  >
                    {bet.stake}
                    {t("lev")}
                  </Typography>
                </Box>{" "}
                <Box width="50%" justifyContent="flex-end" display="flex">
                  {bet.bets.map((bet, i) => (
                    <Typography key={i} sx={{ fontSize: 14, ml: 1 }}>
                      {`${t("slip")}[${i + 1}]:`}
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: 2,
                          color: "#6DBE71",
                        }}
                      >
                        {bet}
                        {t("lev")}
                      </span>
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box width="100%">
          <PillButton
            variant="contained"
            sx={{ mt: 2, ml: 6, width: "300px" }}
            onClick={calculatePossibleBets}
          >
            {t("calculate")}
          </PillButton>
        </Box>
      </Box>
    </Container>
  );
};

export default ArbitrageCalculator;
