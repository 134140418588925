import styled from "@emotion/styled";
import { AddCircle, Cancel, CheckCircle } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import React, { useState } from "react";
import bookies from "../../config/bookies";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.mode === "dark" ? "#F2F2F2" : "#444",
}));

const AddBookmaker = ({ disabled, addBookmaker }) => {
  const { t } = useTranslation("common");
  const [isAddBookmaker, setIsAddBookmaker] = useState(false);
  const [selectedBookie, setSelectedBookie] = useState("");
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isCustomBookie, setIsCustomBookie] = useState(false);

  const CustomOutlineTextField = styled(TextField)(() => ({
    "& label.Mui-focused": {
      color: bookies[selectedBookie]?.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: bookies[selectedBookie]?.color,
      },
      "&.Mui-focused fieldset": {
        borderColor: bookies[selectedBookie]?.color,
      },
    },
  }));

  const CustomFocusedSelectField = styled(Select)(() => ({
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: bookies[selectedBookie]?.color,
    },
  }));

  const CustomInputLabel = styled(InputLabel)(() => ({
    marginLeft: 8,
    "&.Mui-focused": {
      color: bookies[selectedBookie]?.color,
      marginLeft: 8,
    },
  }));

  const handleSelectBookie = (e) => {
    if (e.target.value === "other") {
      setIsCustomBookie(true);
      setSelectedBookie("");
      return;
    }
    setSelectedBookie(e.target.value);
  };

  const handleAddBookmaker = async () => {
    setLoading(true);
    await addBookmaker(selectedBookie, balance);
    setSelectedBookie("");
    setBalance(0);
    setIsAddBookmaker(false);
    setLoading(false);
  };

  const handleCloseCustomBookie = () => {
    setSelectedBookie("");
    setIsCustomBookie(false);
  };

  return (
    <Grid item xs={2.4} mt={2}>
      <Grid container>
        <Grid item xs={12}>
          <Tooltip
            placement="right"
            disableHoverListener={!disabled}
            disableFocusListener={!disabled}
            arrow
            title={
              <Trans i18nKey="tierLimitReached">
                You have reached maximul allowed bookmakers. If you want to add
                more, please upgrade your subscription
                <Link href="/subscription"> here</Link>.
              </Trans>
            }
          >
            <Item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "142px",
                cursor: disabled && "not-allowed",
              }}
            >
              {loading ? (
                <CircularProgress color="success" />
              ) : (
                <>
                  {isAddBookmaker ? (
                    <FormControl sx={{ paddingX: 1 }}>
                      {!isCustomBookie ? (
                        <>
                          <CustomInputLabel id="add-bookmaker-label">
                            {t("chooseBookmaker")}
                          </CustomInputLabel>
                          <CustomFocusedSelectField
                            labelId="add-bookmaker-label"
                            id="add-bookmaker"
                            label={t("chooseBookmaker")}
                            onChange={handleSelectBookie}
                            value={selectedBookie}
                          >
                            {Object.keys(bookies).map((bookie) => (
                              <MenuItem key={bookie} value={bookie}>
                                {bookies[bookie].name}
                              </MenuItem>
                            ))}
                            <MenuItem value="other">{t("other")}</MenuItem>
                          </CustomFocusedSelectField>
                        </>
                      ) : (
                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            id={`other-input`}
                            name="other"
                            type="text"
                            sx={{
                              width: "72%",
                              textAlign: "right",
                              ml: 1,
                            }}
                            label={t("name")}
                            onBlur={(e) => setSelectedBookie(e.target.value)}
                            defaultValue={selectedBookie}
                          />
                          <IconButton
                            color="error"
                            component="span"
                            onClick={handleCloseCustomBookie}
                          >
                            <Cancel
                              sx={{
                                fontSize: "32px",
                                color: (theme) => theme.palette.error.main,
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <CustomOutlineTextField
                          id={`${selectedBookie}-input`}
                          name={selectedBookie}
                          type="number"
                          sx={{
                            width: "72%",
                            textAlign: "right",
                            ml: 1,
                          }}
                          size="small"
                          label={t("balance")}
                          onBlur={(e) => setBalance(e.target.value)}
                          defaultValue={balance}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {t("currency")}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={handleAddBookmaker}
                          disabled={!selectedBookie}
                        >
                          <CheckCircle
                            sx={{
                              fontSize: "32px",
                              color: bookies[selectedBookie]?.color,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </FormControl>
                  ) : (
                    <Tooltip placement="top" arrow title={t("addBookmaker")}>
                      <IconButton
                        sx={{ color: "#77A0FF" }}
                        component="span"
                        onClick={() => setIsAddBookmaker(true)}
                        disabled={disabled}
                      >
                        <AddCircle sx={{ fontSize: "70px" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Item>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddBookmaker;
