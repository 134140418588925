import {
  Block,
  CheckCircleOutline,
  RadioButtonUnchecked,
  RemoveCircleOutline,
} from "@mui/icons-material";

export const statuses = {
  pending: {
    status: "PENDING",
    displayName: "Pending",
    icon: <RadioButtonUnchecked style={{ color: "#ffc411" }} />,
  },
  lost: {
    status: "LOST",
    displayName: "Lost",
    icon: <RemoveCircleOutline style={{ color: "#ff5555" }} />,
  },
  void: {
    status: "VOID",
    displayName: "Void",
    icon: <Block style={{ color: "#ccccff" }} />,
  },
  won: {
    status: "WON",
    displayName: "Won",
    icon: <CheckCircleOutline style={{ color: "#00ca65" }} />,
  },
  half_won: {
    status: "HALF_WON",
    displayName: "Half won",
    icon: <CheckCircleOutline style={{ color: "#00ca65" }} />,
  },
  half_lost: {
    status: "HALF_LOST",
    displayName: "Half lost",
    icon: <RemoveCircleOutline style={{ color: "#ff5555" }} />,
  },
};

export default statuses;
