import styled from "@emotion/styled";
import {
  Block,
  CheckCircleOutline,
  DoubleArrow,
  RadioButtonUnchecked,
  RemoveCircleOutline,
  SaveAs,
  SportsSoccer,
  ThumbsUpDown,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import BookmakerCard from "../BookmakerCard";
import AddBookmaker from "../AddBookmaker/AddBookmaker";
import { tierLimit } from "../../config/bookies";
import axios from "axios";
import useCustomSnackbar from "../../hooks/useCustomSnackbar";
import { format } from "date-fns";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { API_URL } from "../../config/config";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.mode === "dark" ? "#F2F2F2" : "#444",
}));

const StatsComponent = ({
  stats,
  initialProviders,
  bankSizeInitial,
  subscribed,
}) => {
  const { t } = useTranslation("common");
  const snackbar = useCustomSnackbar();
  const currentMonth = format(new Date(), "LLLL");

  const user = useAuthUser();
  const header = useAuthHeader();
  const token = header();

  const [isAddBookmakerDisabled, setIsAddBookmakerDisabled] = useState(false);
  const [bankSize, setBankSize] = useState(bankSizeInitial);
  const [providers, setProviders] = useState(initialProviders);
  const [loading, setLoading] = useState(false);
  const [monthProfitPct, setMonthProfitPct] = useState(0);

  useEffect(() => {
    const { tier } = user();
    setIsAddBookmakerDisabled(providers.length >= tierLimit[tier]);
    // eslint-disable-next-line
  }, [user()]);

  useEffect(() => {
    setProviders(initialProviders);
  }, [initialProviders]);

  useEffect(() => {
    setBankSize(bankSizeInitial);
    const currentMonthNetProfit = stats?.selectedMonth?.netProfit || 0;
    let monthProfitPct = 0;
    if (currentMonthNetProfit > 0) {
      monthProfitPct = parseFloat(currentMonthNetProfit) / bankSizeInitial;
    }
    setMonthProfitPct(monthProfitPct);
  }, [bankSizeInitial, stats]);

  const percentages = {
    halfPercent: {
      val: bankSize * 0.005,
    },
    onePercent: {
      val: bankSize * 0.01,
    },
    oneAndHalfPercent: {
      val: bankSize * 0.015,
    },
    twoPercent: {
      val: bankSize * 0.02,
    },
  };

  const updateProvider = async (providerData) => {
    const { providerName, _id, balance, subname } = providerData;

    try {
      const response = await axios.patch(
        `${API_URL}/users/${user().id}/provider`,
        {
          providerName,
          _id,
          balance,
          subname, // Include subname if provided
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response && response.status === 200) {
        snackbar.showSuccess(t("providerUpdateSuccess"));
        setProviders(response.data.providers);
      }
    } catch (e) {
      snackbar.showError(e.message);
    }
  };

  const deleteProvider = async (providerId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/users/${user().id}/provider/${providerId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response && response.status === 200) {
        snackbar.showSuccess(t("providerUpdateSuccess"));
        setProviders(response.data);
      }
    } catch (e) {
      snackbar.showError(e.message);
    }
  };

  const handleUpdateBankSize = async () => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${API_URL}/users/${user().id}`,
        {
          bankSize,
        },
        { headers: { Authorization: token } }
      );

      if (response && response.status === 200) {
        snackbar.showSuccess(t("bankUpdateSuccess"));
        setBankSize(response.data.bankSize);
      }
      setLoading(false);
    } catch (e) {
      snackbar.showError(e.message);
    }
  };

  const handleAddBookmaker = async (providerName, balance) => {
    try {
      const providers = await axios.post(
        `${API_URL}/users/${user().id}/provider`,
        {
          providerName,
          balance,
        },
        { headers: { Authorization: token } }
      );
      if (providers && providers.status === 200) {
        snackbar.showSuccess(t("providerSuccessfullyAdded"));
        setProviders(providers.data);
      }
    } catch (e) {
      snackbar.showError(t("errorAddingProvider") + e.message);
    }
  };

  return (
    <Grid container spacing={1}>
      {/* statistics */}
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item>
              <Typography fontSize={20}>{t("stats")}</Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Grid spacing={1} container>
              <Grid item xs={6}>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <Item>
                      <Typography>{t("allStats")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <SportsSoccer sx={{ ml: 2 }} />
                      <Typography sx={{ ml: 2 }}>{t("count")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{ backgroundColor: (theme) => theme.palette.success }}
                    >
                      <Typography>{stats?.totals?.count || 0}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <CheckCircleOutline sx={{ ml: 2 }} color="success" />
                      <Typography sx={{ ml: 2 }}>{t("won")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) => theme.palette.success.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.totals?.won || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <RemoveCircleOutline sx={{ ml: 2 }} color="error" />
                      <Typography sx={{ ml: 2 }}>{t("lost")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) => theme.palette.error.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.totals?.lost || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <RadioButtonUnchecked sx={{ ml: 2 }} color="warning" />
                      <Typography sx={{ ml: 2 }}>{t("pending")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) => theme.palette.warning.light,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.totals?.pending || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <Block sx={{ ml: 2 }} color="disabled" />
                      <Typography sx={{ ml: 2 }}>{t("void")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: "#e2e2e2",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#444",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.totals?.void || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <ThumbsUpDown
                        sx={{ ml: 2 }}
                        color={
                          stats?.totals?.profitPercent >= 0
                            ? "success"
                            : "error"
                        }
                      />
                      <Typography sx={{ ml: 2 }}>{t("winRate")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) =>
                          stats?.totals?.profitPercent >= 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.totals?.profitPercent.toFixed(2) || 0}%
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <ThumbsUpDown
                        sx={{ ml: 2 }}
                        color={
                          stats?.totals?.avgOdds > 1.8
                            ? "success"
                            : stats?.totals?.avgOdds >= 1.4
                            ? "warning"
                            : "error"
                        }
                      />
                      <Typography sx={{ ml: 2 }}>{t("avgOdds")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) =>
                          stats?.totals?.avgOdds > 1.8
                            ? theme.palette.success.main
                            : stats?.totals?.avgOdds >= 1.4
                            ? theme.palette.warning.light
                            : theme.palette.error.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.totals?.avgOdds.toFixed(2) || 0}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <Item>
                      <Typography>{t("monthlyStats")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <SportsSoccer sx={{ ml: 2 }} />
                      <Typography sx={{ ml: 2 }}>{t("count")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{ backgroundColor: (theme) => theme.palette.success }}
                    >
                      <Typography>
                        {stats?.selectedMonth?.count || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <CheckCircleOutline sx={{ ml: 2 }} color="success" />
                      <Typography sx={{ ml: 2 }}>{t("won")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) => theme.palette.success.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.selectedMonth?.won || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <RemoveCircleOutline sx={{ ml: 2 }} color="error" />
                      <Typography sx={{ ml: 2 }}>{t("lost")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) => theme.palette.error.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.selectedMonth?.lost || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <RadioButtonUnchecked sx={{ ml: 2 }} color="warning" />
                      <Typography sx={{ ml: 2 }}>{t("pending")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) => theme.palette.warning.light,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.selectedMonth?.pending || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <Block sx={{ ml: 2 }} color="disabled" />
                      <Typography sx={{ ml: 2 }}>{t("void")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: "#e2e2e2",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#444",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.selectedMonth?.void || 0}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <ThumbsUpDown
                        sx={{ ml: 2 }}
                        color={
                          stats?.selectedMonth?.profitPercent >= 0
                            ? "success"
                            : "error"
                        }
                      />
                      <Typography sx={{ ml: 2 }}>{t("winRate")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) =>
                          stats?.selectedMonth?.profitPercent >= 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.selectedMonth?.profitPercent || 0}%
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item sx={{ display: "flex" }}>
                      <ThumbsUpDown
                        sx={{ ml: 2 }}
                        color={
                          stats?.selectedMonth?.avgOdds > 1.8
                            ? "success"
                            : stats?.selectedMonth?.avgOdds >= 1.4
                            ? "warning"
                            : "error"
                        }
                      />
                      <Typography sx={{ ml: 2 }}>{t("avgOdds")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) =>
                          stats?.selectedMonth?.avgOdds > 1.8
                            ? theme.palette.success.main
                            : stats?.selectedMonth?.avgOdds >= 1.4
                            ? theme.palette.warning.light
                            : theme.palette.error.main,
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                        }}
                      >
                        {stats?.selectedMonth?.avgOdds || 0}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* bank info */}
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item>
              <Typography fontSize={20}>{t("bank")}</Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Grid spacing={1} container>
              <Grid item xs={6}>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <Item>
                      <Typography>{t("bankTotal")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "76px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "light" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                          fontSize: 27,
                        }}
                      >
                        {providers
                          .reduce(
                            (prev, curr) => prev + parseFloat(curr.balance),
                            parseFloat(stats?.totals?.inPlay)
                          )
                          .toFixed(2)}{" "}
                        {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <Typography>
                        {t("currentMonthNetProfit", {
                          month: t(currentMonth),
                        })}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "76px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            stats?.selectedMonth?.netProfit >= 0
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                          fontWeight: "bold",
                          fontSize: 27,
                        }}
                      >
                        {stats?.selectedMonth?.netProfit || 0} {t("currency")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{
                            position: "absolute",
                            top: -35,
                            width: "70px",
                            fontWeight: "bold",
                            right: -80,
                            fontSize: "12px",
                            color: (theme) =>
                              stats?.selectedMonth?.netProfit >= 0
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                          }}
                        >
                          {(monthProfitPct * 100).toFixed(2)} %
                        </Typography>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? "#77A0FF"
                            : theme.palette.primary.main,
                        height: "65px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#444" : "#e2e2e2",
                          fontWeight: "bold",
                          fontSize: 21,
                          lineHeight: 1,
                        }}
                      >
                        {stats?.totals?.inPlay.toFixed(2) || 0} {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        height: "100%",
                      }}
                    >
                      <DoubleArrow sx={{ transform: "rotate(180deg)" }} />
                      <Typography
                        sx={{ fontWeight: "bold", fontSize: 27, mr: 3 }}
                      >
                        {t("inPlay")}
                      </Typography>
                      {/* <TextField
                        id="inplay-input"
                        name="inplay"
                        type="number"
                        sx={{
                          width: "72%",
                          textAlign: "right",
                        }}
                        size="small"
                        label={t("inPlay")}
                        focused
                        // onBlur={handleInPlay}
                        // defaultValue={inPlayBalance}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("currency")}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        // onClick={handleUpdateInPlay}
                      >
                        <CheckCircle
                          sx={{
                            fontSize: "32px",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#77A0FF"
                                : theme.palette.primary.main,
                          }}
                        />
                      </IconButton> */}
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <Item>
                      <Typography>{t("bettingBank")}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      {subscribed ? (
                        <>
                          <TextField
                            id="amount-input"
                            name="amount"
                            type="number"
                            sx={{
                              width: "72%",
                              textAlign: "right",
                            }}
                            size="small"
                            label={t("amount")}
                            focused
                            onChange={(e) => setBankSize(e.target.value)}
                            value={bankSize}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {t("currency")}
                                </InputAdornment>
                              ),
                            }}
                          />

                          {loading ? (
                            <CircularProgress
                              color="inherit"
                              sx={{ my: 0.5, mx: 0.5 }}
                            />
                          ) : (
                            <IconButton
                              color="primary"
                              component="span"
                              onClick={handleUpdateBankSize}
                            >
                              <SaveAs
                                sx={{
                                  fontSize: "32px",
                                  color: (theme) =>
                                    theme.palette.mode === "dark"
                                      ? "#77A0FF"
                                      : theme.palette.primary.main,
                                }}
                              />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        <Typography>{bankSize}</Typography>
                      )}
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        0.5% =
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#77A0FF"
                              : theme.palette.primary.main,
                          ml: 1,
                          fontStyle: "none",
                        }}
                      >
                        {percentages.halfPercent.val} {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        1% =
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#77A0FF"
                              : theme.palette.primary.main,
                          ml: 1,
                          fontStyle: "none",
                        }}
                      >
                        {percentages.onePercent.val} {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        1.5% =
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#77A0FF"
                              : theme.palette.primary.main,
                          ml: 1,
                          fontStyle: "none",
                        }}
                      >
                        {percentages.oneAndHalfPercent.val} {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "italic",
                        }}
                      >
                        2% =
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#77A0FF"
                              : theme.palette.primary.main,
                          ml: 1,
                          fontStyle: "none",
                        }}
                      >
                        {percentages.twoPercent.val} {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        height: "56px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: 12,
                        }}
                      >
                        {t("thisYearProfit")}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: (theme) =>
                            stats?.yearly?.yearlyProfit >= 0
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                          ml: 1,
                          fontStyle: "none",
                          fontSize: 18,
                        }}
                      >
                        {stats?.yearly?.yearlyProfit.toFixed(2)} {t("currency")}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* providers */}
      {subscribed && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {providers.map((provider, i) => (
              <BookmakerCard
                provider={provider}
                key={provider._id}
                updateProvider={updateProvider}
                deleteProvider={deleteProvider}
                inGame={stats.totals.inGame}
              />
            ))}
            {/* TODO => revert to 5, or make pro max bookies to 10 */}
            {providers.length < 10 && (
              <AddBookmaker
                disabled={isAddBookmakerDisabled}
                addBookmaker={handleAddBookmaker}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default StatsComponent;
