import styled from "@emotion/styled";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import ImageComponent from "../ImageComponent/ImageComponent";
import { MEDIA_URL } from "../../config/config";
import EditModeView from "./EditModeView";
import PresentationalView from "./PresentationalView";
import ActionButtons from "./ActionButtons";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.mode === "dark" ? "#F2F2F2" : "#444",
  backgroundColor: theme.palette.mode === "light" && "#282C34",
}));

const BookmakerCard = ({
  provider,
  updateProvider,
  deleteProvider,
  inGame,
}) => {
  const { t } = useTranslation("common");

  const [providerBalance, setProviderBalance] = useState(provider.balance);
  const [providerSubname, setProviderSubname] = useState(
    provider.subname || ""
  );
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);

  const handleUpdateProvider = async ({ providerName, _id }) => {
    setLoading(true);
    await updateProvider({
      providerName,
      _id,
      balance: providerBalance,
      subname: providerSubname,
    });
    setLoading(false);
    setIsInEditMode(false);
  };

  const handleDeleteProvider = async (providerId) => {
    setLoading(true);
    await deleteProvider(providerId);
    setLoading(false);
  };

  useEffect(() => {
    setProviderBalance(provider.balance);
    setProviderSubname(provider.subname || "");
  }, [provider]);

  return (
    <Grid item xs={2.4} mt={2}>
      <Grid container position="relative" sx={{ height: "100%" }}>
        {loading ? (
          <Grid item xs={12}>
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "142px",
              }}
            >
              <CircularProgress color="success" />
            </Item>
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ display: "flex", height: "100%" }}>
            <Item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ActionButtons setIsInEditMode={setIsInEditMode} />
                {inGame?.[`${provider._id}`]?.totalStaked && (
                  <Box
                    sx={{
                      display: "flex",
                      color: "#faaf00",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#8080ff",
                        fontSize: "0.8rem",
                      }}
                    >
                      {t("inGame")}{" "}
                      <Typography
                        component="span"
                        sx={{
                          color: (theme) => theme.palette.warning.main,
                        }}
                      >
                        {inGame?.[`${provider._id}`]?.totalBets}
                      </Typography>{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                      }}
                    >
                      {inGame?.[`${provider._id}`]?.totalStaked.toFixed(2)}
                      {t("currency")}
                    </Typography>
                  </Box>
                )}
              </Box>
              <ImageComponent
                src={`${MEDIA_URL}${provider.providerName}.png`}
                baseUrl={MEDIA_URL}
                providerName={provider.providerName}
                fallbackExt="svg"
                style={{ width: 175, height: 70, objectFit: "contain" }}
              />
              {isInEditMode ? (
                <EditModeView
                  provider={provider}
                  providerBalance={providerBalance}
                  setProviderBalance={setProviderBalance}
                  providerSubname={providerSubname}
                  setProviderSubname={setProviderSubname}
                  handleUpdateProvider={handleUpdateProvider}
                  handleDeleteProvider={handleDeleteProvider}
                  isDeleteModalOpen={isDeleteModalOpen}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                  t={t}
                />
              ) : (
                <PresentationalView
                  provider={provider}
                  providerBalance={providerBalance}
                  t={t}
                />
              )}
            </Item>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BookmakerCard;
