import { CircularProgress, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
// import useCustomSnackbar from "../hooks/useCustomSnackbar";
import { format } from "date-fns";
import { API_URL } from "../config/config";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

const Subscription = () => {
  const { t } = useTranslation("common");
  //   const snackbar = useCustomSnackbar();
  //   const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({});
  const user = useAuthUser();
  const header = useAuthHeader();
  const token = header();

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/${user().id}`, {
        headers: {
          Authorization: token,
        },
      });
      if (response && response.status === 200) {
        setUserData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ paddingY: "80px" }}>
        <Typography sx={{ fontSize: 27, fontWeight: 500, mt: 2 }}>
          {t("Subscription")}{" "}
        </Typography>
        {Object.keys(userData).length > 0 ? (
          <>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: "flex" }}>
                <Typography>{t("status")}:</Typography>
                {userData.subscriptionStatus === "trialing" ||
                userData.subscriptionStatus === "active" ? (
                  <Typography
                    sx={{
                      ml: 2,
                      fontWeight: "600",
                      color: "#00ca65",
                      fontStyle: "italic",
                    }}
                  >
                    {t("active").toUpperCase()}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      ml: 2,
                      fontWeight: "600",
                      color: "#ff5555",
                      fontStyle: "italic",
                    }}
                  >
                    {t("inactive").toUpperCase()}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography>{t("endsOn")}:</Typography>
                {userData.subscriptionStatus === "trialing" ||
                userData.subscriptionStatus === "active" ? (
                  <Typography
                    sx={{
                      ml: 2,
                      fontWeight: "600",
                    }}
                  >
                    {format(new Date(userData.subscriptionEnd), "dd-MM-yyyy")}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      ml: 2,
                      fontWeight: "600",
                    }}
                  >
                    {t("inactive").toUpperCase()}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              component="form"
              //   onSubmit={handleSubmit(handleUpdateUser)}
              noValidate
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            ></Box>
          </>
        ) : (
          <Box
            sx={{
              mt: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default Subscription;
