import { LockReset } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { PillButton } from "../components";
import useCustomSnackbar from "../hooks/useCustomSnackbar";
import { Navigate, useNavigate } from "react-router-dom";
import { API_URL } from "../config/config";
import { useIsAuthenticated } from "react-auth-kit";

const ForgotPassword = () => {
  const { t } = useTranslation("common");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  //   const locale = localStorage.getItem("locale") || "bg";

  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const snackbar = useCustomSnackbar();

  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated()) {
    return <Navigate to="/dashboard" />;
  }

  const handleResendVerificationEmail = async (values) => {
    try {
      const verification = await axios.post(`${API_URL}/auth/forgot-password`, {
        email: values.email,
      });
      if (verification && verification.status === 204) {
        setEmailSentSuccess(true);
        snackbar.showInfo(t("resetPasswordEmailSuccessfullySent"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 20,
          border: (theme) =>
            `1px solid ${theme.palette.mode === "light" ? "#444" : "#f2f2f2"}`,
          borderRadius: "6px",
          p: 4,
          flexDirection: "column",
        }}
      >
        <LockReset
          sx={{
            fontSize: 75,
            stroke: (theme) =>
              theme.palette.mode === "light" ? "#f2f2f2" : "#282C34",
            strokeWidth: 0.5,
            color: "#ffc411",
          }}
        />
        <Typography
          sx={{
            my: 4,
            fontWeight: "300",
            fontSize: 27,
            textAlign: "center",
          }}
        >
          {t("passwordResetHeadline")}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            fontWeight: "300",
            fontSize: 21,
            textAlign: "center",
          }}
        >
          {t("sendResetPasswordEmail")}
        </Typography>
        {emailSentSuccess ? (
          <Typography sx={{ my: 2 }}>
            {t("checkYourEmailForInstructionsToResetYourPassword")}
          </Typography>
        ) : (
          <TextField
            color="success"
            margin="normal"
            required
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+\.\S+$/,
            })}
            error={!!errors.email}
            helperText={errors.email && t("emailRequired")}
          />
        )}
        <PillButton
          variant="outlined"
          sx={{ fontWeight: "400", mt: 1 }}
          onClick={
            emailSentSuccess
              ? () => navigate("/login")
              : handleSubmit(handleResendVerificationEmail)
          }
        >
          {emailSentSuccess
            ? t("close").toUpperCase()
            : t("send").toUpperCase()}
        </PillButton>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
