import styled from "@emotion/styled";
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import flagBG from "../assets/images/flagBG.png";
import flagEN from "../assets/images/flagEN.png";
import { useTranslation } from "react-i18next";
import PillButton from "../components/PillButton/PillButton";
import { AccountBalance, AccountCircle, Calculate } from "@mui/icons-material";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const MainLayout = ({ children, locale, setLocale, setTheme, isDarkTheme }) => {
  const signOut = useSignOut();
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    signOut();
    navigate("/");
  };

  const isAuthenticated = useIsAuthenticated();

  return (
    <Box zIndex={99}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          height: 45,
          width: "100%",
          zIndex: 99,
        }}
      >
        <Box sx={{ float: "left", ml: 4, pt: 1 }}>
          {!isAuthenticated() && (
            <>
              {(location.pathname === "/login" ||
                location.pathname === "/register") && (
                <Link href="/" sx={{ textDecoration: "none", mr: 2 }}>
                  <PillButton variant="contained">{t("home")}</PillButton>
                </Link>
              )}

              {location.pathname !== "/login" && (
                <Link href="/login" sx={{ textDecoration: "none" }}>
                  <PillButton variant="contained" sx={{ fontWeight: 700 }}>
                    {t("signIn")}
                  </PillButton>
                </Link>
              )}
            </>
          )}
          {isAuthenticated() && (
            <>
              <PillButton
                variant="outlined"
                sx={{ fontWeight: "bold" }}
                onClick={logOut}
              >
                {t("signOut")}
              </PillButton>
              <Link href="/dashboard" sx={{ textDecoration: "none", ml: 2 }}>
                <PillButton variant="contained">{t("dashboard")}</PillButton>
              </Link>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Link href="/profile" sx={{ textDecoration: "none" }}>
                  <MenuItem>
                    <AccountCircle sx={{ mr: 1, fill: "#f2f2f2" }} />
                    <Typography
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "dark" ? "#f2f2f2" : "#444",
                        fontWeight: 500,
                      }}
                    >
                      {t("profile")}
                    </Typography>
                  </MenuItem>
                </Link>

                <Link href="/subscription" sx={{ textDecoration: "none" }}>
                  <MenuItem>
                    <AccountBalance sx={{ mr: 1, fill: "#f2f2f2" }} />
                    <Typography
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "dark" ? "#f2f2f2" : "#444",
                        fontWeight: 500,
                      }}
                    >
                      {t("subscription")}
                    </Typography>
                  </MenuItem>
                </Link>
              </Menu>
              <IconButton
                variant="contained"
                onClick={() => navigate("/arbitrage-calculator")}
                disableTouchRipple
                sx={{ ml: 1 }}
              >
                <Calculate sx={{ fontSize: 40, fill: "#247909" }} />
              </IconButton>
              <IconButton
                variant="contained"
                onClick={handleClick}
                disableTouchRipple
              >
                <AccountCircle
                  sx={{
                    fontSize: 40,
                    fill: "#247909",
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
        <Box sx={{ float: "right", mr: 4, pt: 1 }}>
          <Box
            sx={{
              width: 140,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <MaterialUISwitch onChange={setTheme} checked={isDarkTheme} />
            <Box
              component="img"
              sx={{
                cursor: "pointer",
                height: 30,
                width: 30,
                opacity: locale === "en" ? "30%" : "100%",
              }}
              id="bg"
              onClick={setLocale}
              src={flagBG}
              alt="country flag"
            />
            <Box
              component="img"
              sx={{
                cursor: "pointer",
                height: 30,
                width: 30,
                opacity: locale === "bg" ? "30%" : "100%",
              }}
              id="en"
              onClick={setLocale}
              src={flagEN}
              alt="country flag"
            />
          </Box>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default MainLayout;
