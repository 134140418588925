import { CheckCircleOutlined, HighlightOff } from "@mui/icons-material";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PillButton } from "../components";
import useCustomSnackbar from "../hooks/useCustomSnackbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL } from "../config/config";

const VerifyEmail = () => {
  const { t } = useTranslation("common");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const snackbar = useCustomSnackbar();

  const handleResendVerificationEmail = async (values) => {
    try {
      const verification = await axios.post(
        `${API_URL}/auth/resend-verification-email`,
        {
          email: values.email,
        }
      );
      if (verification && verification.status === 204) {
        setEmailSentSuccess(true);
        snackbar.showInfo(t("verificationEmailResendSuccessMessage"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [verificationSuccessful, setVerificationSuccessful] = useState(null);

  const verifyEmail = async () => {
    try {
      const token = searchParams.get("token");
      if (token) {
        const verification = await axios.post(
          `${API_URL}/auth/verify-email?token=${token}`
        );
        if (verification && verification.status === 204) {
          setVerificationSuccessful(true);
        }
      }
    } catch (e) {
      setVerificationSuccessful(false);
    }
  };

  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line
  }, []);

  if (verificationSuccessful === null) {
    return (
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 20,
          }}
        >
          <CircularProgress color="success" />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 20,
          border: (theme) =>
            `1px solid ${theme.palette.mode === "light" ? "#444" : "#f2f2f2"}`,
          borderRadius: "6px",
          p: 4,
          flexDirection: "column",
        }}
      >
        {verificationSuccessful ? (
          <>
            <CheckCircleOutlined
              sx={{
                fontSize: 75,
                stroke: (theme) =>
                  theme.palette.mode === "light" ? "#f2f2f2" : "#282C34",
                strokeWidth: 1,
                color: "#247909",
              }}
            />
            <Typography
              sx={{
                my: 4,
                fontWeight: "300",
                fontSize: 27,
                textAlign: "center",
              }}
            >
              {t("verificationSuccessfulText")}
            </Typography>
          </>
        ) : (
          <>
            <HighlightOff
              sx={{
                fontSize: 75,
                stroke: (theme) =>
                  theme.palette.mode === "light" ? "#f2f2f2" : "#282C34",
                strokeWidth: 1,
                color: "#ff5555",
              }}
            />
            <Typography
              sx={{
                my: 4,
                fontWeight: "300",
                fontSize: 27,
                textAlign: "center",
              }}
            >
              {t("verificationNotSuccessfulInfo")}
            </Typography>
            {!emailSentSuccess && (
              <Typography
                sx={{
                  mb: 1,
                  fontWeight: "300",
                  fontSize: 21,
                  textAlign: "center",
                }}
              >
                {t("resendVerificationEmail")}
              </Typography>
            )}
            {emailSentSuccess ? (
              <Typography sx={{ my: 2 }}>
                {t("checkYourEmailForInstructions")}
              </Typography>
            ) : (
              <TextField
                color="success"
                margin="normal"
                required
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/,
                })}
                error={!!errors.email}
                helperText={errors.email && t("emailRequired")}
              />
            )}
          </>
        )}
        <PillButton
          variant="outlined"
          sx={{ fontWeight: "400", mt: 1 }}
          onClick={
            emailSentSuccess || verificationSuccessful
              ? () => navigate("/login")
              : handleSubmit(handleResendVerificationEmail)
          }
        >
          {emailSentSuccess || verificationSuccessful
            ? t("close").toUpperCase()
            : t("send").toUpperCase()}
        </PillButton>
      </Box>
    </Container>
  );
};

export default VerifyEmail;
