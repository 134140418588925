import { CheckCircleOutlined, LockOutlined } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PillButton from "../components/PillButton/PillButton";
import Cookies from "js-cookie";
import { useIsAuthenticated } from "react-auth-kit";
import { Navigate, useSearchParams } from "react-router-dom";
import { API_URL } from "../config/config";

const Register = () => {
  const { t } = useTranslation("common");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [errorMsg, setErrorMsg] = useState("");
  const [selected, setSelected] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryTier = searchParams.get("tier");

    if (queryTier) {
      setSelected(queryTier);
    }
    // eslint-disable-next-line
  }, []);

  if (isAuthenticated()) {
    return <Navigate to="/dashboard" />;
  }

  const handleSelectSubscription = (e) => {
    setSelected(e.target.value);
  };

  const stripeLookupKeys = {
    beginner: "price_1LD6AZHp1wSMi5sOFYpPcbya",
    advanced: "price_1LD3xSHp1wSMi5sO8Rgrj7jP",
    pro: "price_1LD69XHp1wSMi5sO9w1Rsv6s",
  };

  const handleRegister = async (values) => {
    setLoading(true);
    const locale = Cookies.get("locale");
    const theme = Cookies.get("theme");
    const registrationData = values;
    Object.assign(registrationData, {
      lookupKey: stripeLookupKeys[selected],
      tier: selected.toUpperCase(),
      locale: locale || "bg",
      theme: theme || "dark",
    });
    delete registrationData.confirmPassword;
    try {
      const response = await axios.post(
        `${API_URL}/auth/register`,
        registrationData
      );
      if (response && response.status === 201) {
        setSuccess(true);
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        setErrorMsg(e.response.data.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {!success ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "info.dark" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("register")}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(handleRegister)}
              noValidate
              sx={{ mt: 1 }}
            >
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              <TextField
                color="success"
                margin="normal"
                required
                fullWidth
                id="username"
                label={t("username")}
                name="username"
                autoComplete="username"
                autoFocus
                {...register("username", {
                  required: true,
                })}
                error={!!errors.username}
                helperText={errors.username && t("usernameRequired")}
              />
              <TextField
                color="success"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/,
                })}
                error={!!errors.email}
                helperText={errors.email && t("emailRequired")}
              />
              <TextField
                color="success"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
                {...register("password", { required: true, minLength: 6 })}
                error={!!errors.password}
                helperText={errors.password && t("passwordRequired")}
              />
              <TextField
                margin="normal"
                color="success"
                required
                fullWidth
                name="confirmPassword"
                label={t("confirmPassword")}
                type="password"
                id="confirmPassword"
                {...register("confirmPassword", {
                  required: true,
                  minLength: 6,
                  validate: (pass) => {
                    if (watch("password") !== pass)
                      return t("passwordsDontMatch");
                  },
                })}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword && t("passwordsDontMatch")}
              />
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="subscription-label" sx={{ mt: 1 }}>
                  {t("pleaseChooseSubscription")} *
                </FormLabel>
                <RadioGroup
                  aria-labelledby="subscription-label"
                  name="subscription"
                  sx={{ width: "100%" }}
                  onChange={handleSelectSubscription}
                >
                  <FormControlLabel
                    sx={{
                      px: 2,
                      py: 1,
                      border:
                        selected === "beginner" ? "1px solid #247909" : "none",
                      borderRadius: 2,
                      mt: 2,
                      width: "100%",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? "#E0E0E0" : "#444",
                      ml: 0,
                    }}
                    value="beginner"
                    control={
                      <Radio
                        checked={selected === "beginner"}
                        color="success"
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 1,
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          {t("beginner")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 300,
                            ml: 1,
                            fontStyle: "italic",
                            color: (theme) =>
                              theme.palette.mode === "dark" ? "#ccc" : "#444",
                            fontSize: 13,
                          }}
                        >
                          {t("freeTrial")} &#x2022; {t("oneBookmaker")} &#x2022;{" "}
                          {t("oneMonthStats")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 300,
                            ml: 2,
                            fontStyle: "italic",
                            color: (theme) =>
                              theme.palette.mode === "dark" ? "#ccc" : "#444",
                            fontSize: 13,
                          }}
                        >
                          9.95 {t("currency")}/{t("monthly")}
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="advanced"
                    control={
                      <Radio
                        checked={selected === "advanced"}
                        color="success"
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 1,
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          {t("advanced")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 300,
                            ml: 1,
                            fontStyle: "italic",
                            color: (theme) =>
                              theme.palette.mode === "dark" ? "#ccc" : "#444",
                            fontSize: 13,
                          }}
                        >
                          {t("freeTrial")} &#x2022; {t("threeBookmakers")}{" "}
                          &#x2022; {t("threeMonthsStats")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 300,
                            ml: 2,
                            fontStyle: "italic",
                            color: (theme) =>
                              theme.palette.mode === "dark" ? "#ccc" : "#444",
                            fontSize: 13,
                          }}
                        >
                          14.95 {t("currency")}/{t("monthly")}
                        </Typography>
                      </Box>
                    }
                    sx={{
                      px: 2,
                      py: 1,
                      border:
                        selected === "advanced" ? "1px solid #247909" : "none",
                      borderRadius: 2,
                      mt: 1,
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? "#E0E0E0" : "#444",
                      ml: 0,
                    }}
                  />
                  <FormControlLabel
                    value="pro"
                    control={
                      <Radio checked={selected === "pro"} color="success" />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 1,
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          {t("pro")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 300,
                            ml: 1,
                            fontStyle: "italic",
                            color: (theme) =>
                              theme.palette.mode === "dark" ? "#ccc" : "#444",
                            fontSize: 13,
                          }}
                        >
                          {t("freeTrial")} &#x2022; {t("fiveBookmakers")}{" "}
                          &#x2022; {t("lifetimeStats")}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 300,
                            ml: 2,
                            fontStyle: "italic",
                            color: (theme) =>
                              theme.palette.mode === "dark" ? "#ccc" : "#444",
                            fontSize: 13,
                          }}
                        >
                          18.95 {t("currency")}/{t("monthly")}
                        </Typography>
                      </Box>
                    }
                    sx={{
                      px: 2,
                      py: 1,
                      border: selected === "pro" ? "1px solid #247909" : "none",
                      borderRadius: 2,
                      mt: 1,
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? "#E0E0E0" : "#444",
                      ml: 0,
                    }}
                  />
                </RadioGroup>
              </FormControl>
              <FormControlLabel
                sx={{ mt: 2 }}
                control={
                  <Checkbox
                    color="success"
                    {...register("termsAccepted", { required: true })}
                  />
                }
                label={t("termsAccepted")}
              />
              {errors.termsAccepted && (
                <Typography
                  sx={{ fontSize: 12, mt: 1, ml: 2, color: "#F44336" }}
                >
                  {t("termsMustBeAccepted")}
                </Typography>
              )}
              <PillButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 8 }}
                disabled={loading || Object.keys(errors).length > 0}
              >
                {loading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      my: 0.5,
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "#ccc" : "#444",
                    }}
                  />
                )}
                {!loading && t("register")}
              </PillButton>
            </Box>
          </Box>
        </Container>
      ) : (
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 20,
              border: (theme) =>
                `1px solid ${
                  theme.palette.mode === "light" ? "#444" : "#f2f2f2"
                }`,
              borderRadius: "6px",
              p: 4,
              flexDirection: "column",
            }}
          >
            <CheckCircleOutlined
              sx={{
                fontSize: 75,
                stroke: (theme) =>
                  theme.palette.mode === "light" ? "#f2f2f2" : "#282C34",
                strokeWidth: 1,
                color: "#247909",
              }}
            />
            <Typography
              sx={{
                my: 4,
                fontWeight: "300",
                fontSize: 27,
                textAlign: "center",
              }}
            >
              {t("registrationSuccessScreenText")}
            </Typography>
            <Link href="/login" sx={{ textDecoration: "none" }}>
              <PillButton variant="outlined" sx={{ fontWeight: "400" }}>
                {t("close").toUpperCase()}
              </PillButton>
            </Link>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default Register;
