import React from "react";
import { Box, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

const ActionButtons = ({ setIsInEditMode }) => (
  <Box sx={{ display: "flex" }}>
    <IconButton
      sx={{
        p: "0.25rem",
        m: 0,
      }}
      color="primary"
      component="span"
      onClick={() => setIsInEditMode((prev) => !prev)}
    >
      <Edit
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.info.main,
          },
          color: "#888",
        }}
      />
    </IconButton>
  </Box>
);

export default ActionButtons;
