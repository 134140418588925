import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import InputRow from "../InputRow/InputRow";
import statuses from "../../config/statuses";
import { Add, PlaylistAddCheckCircle } from "@mui/icons-material";
import { Controller } from "react-hook-form";

const InputRowForm = ({
  fields,
  register,
  providers,
  handleSubmit,
  errors,
  append,
  remove,
  control,
  setValue,
  bankSize,
}) => {
  const { t } = useTranslation("common");
  const perts = [
    { value: 0.5, marked: false },
    { value: 1, marked: false },
    { value: 1.5, marked: false },
    { value: 2, marked: false },
  ];

  const [percentages, setPercentages] = useState(perts);

  const handlePercentageCompund = (perc, i) => {
    const newPercentages = percentages;

    if (newPercentages[i].marked === true) {
      newPercentages[i].marked = false;
    } else {
      newPercentages[i].marked = true;
    }

    let newValue = 0;

    newPercentages.forEach((perc, i) => {
      if (perc.marked === true) {
        newValue += perc.value;
      }
    });
    setPercentages([...newPercentages]);
    setValue("stake", (bankSize * newValue) / 100);
  };

  const handleSubmitRow = (event) => {
    setPercentages(perts);
    handleSubmit(event);
  };

  return (
    <FormControl
      sx={{
        maxWidth: { xs: "1140px", lg: "initial" },
      }}
    >
      <Typography
        variant="h5"
        display="block"
        color={(theme) => theme.palette.text.secondary}
        sx={{ marginTop: "25px", marginBottom: "25px" }}
        gutterBottom
      >
        {t("slip")}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: { xs: "wrap", lg: "initial" } }}>
        <Box>
          {fields.map((field, index) => (
            <InputRow
              key={field.id}
              field={field}
              index={index}
              register={register}
              errors={errors}
              remove={remove}
            />
          ))}
        </Box>
        <Controller
          name="provider"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              select
              sx={{ width: "130px" }}
              label={t("bookmaker")}
              defaultValue=""
              error={!!errors.provider}
              {...field}
            >
              {providers.map((provider) => (
                <MenuItem value={provider._id} key={provider._id}>
                  {provider.providerName} {provider.subname}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <FormControl>
          <InputLabel id="status-label" style={{ marginLeft: 8 }}>
            {t("status")}
          </InputLabel>
          <Controller
            name="status"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                sx={{ width: "146px", ml: { xs: 0, lg: 1 } }}
                labelId="status-label"
                label={t("status")}
                defaultValue={statuses.pending.status}
                error={!!errors.status}
                required={true}
                {...field}
              >
                {Object.keys(statuses).map((status) => (
                  <MenuItem
                    value={statuses[status].status}
                    key={statuses[status].status}
                  >
                    {t(statuses[status].displayName)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <TextField
          type="number"
          sx={{
            width: "100px",
            ml: { xs: 1, lg: 0 },
          }}
          label={t("stake")}
          focused
          error={!!errors.stake}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{t("currency")}</InputAdornment>
            ),
          }}
          {...register("stake", { required: true })}
        />
        <Box
          sx={{
            position: { lg: "absolute" },
            right: 5,
            top: 0,
            display: "flex",
            width: "100px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            ml: { xs: 1, lg: 0 },
          }}
        >
          {percentages.map((perc, i) => (
            <Button
              onClick={() => handlePercentageCompund(perc.value, i)}
              variant={
                percentages[i].marked === true ? "contained" : "outlined"
              }
              color="warning"
              sx={{ minWidth: 10, mx: 0.25, my: 0.5, px: 1 }}
              key={perc.value}
            >
              <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                {perc.value}%
              </Typography>
            </Button>
          ))}
        </Box>
        <Tooltip
          arrow
          placement="right"
          title={t("pleaseFillAllItemsInRed")}
          disableHoverListener={!Object.keys(errors).length > 0}
        >
          <Box
            sx={{
              height: "56px",
              ml: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSubmitRow}
              style={{ borderRadius: 50 }}
              disabled={Object.keys(errors).length > 0}
              endIcon={<PlaylistAddCheckCircle sx={{ fontSize: "40px" }} />}
            >
              {t("save")}
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Tooltip
        arrow
        placement="left"
        title={t("maxBetLimitReached")}
        disableHoverListener={fields.length < 10}
      >
        <Box sx={{ mt: { xs: 2, lg: -1 } }}>
          <Button
            variant="contained"
            style={{ borderRadius: 50 }}
            startIcon={<Add />}
            onClick={() => append()}
            disabled={Object.keys(errors).length > 0 || fields.length >= 10}
          >
            {t("addEvent")}
          </Button>
        </Box>
      </Tooltip>
    </FormControl>
  );
};

export default InputRowForm;
