import styled from "@emotion/styled";
import { ExpandLess } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";

const ScrollToTop = styled(IconButton)(({ theme }) => ({
  zIndex: 2,
  position: "fixed",
  bottom: "15vh",
  backgroundColor: theme.palette.mode === "dark" ? "#DCDCDC" : "#001E3C",
  color: theme.palette.mode === "dark" ? "#444444" : "#e2e2e2",
  "&:hover, &.Mui-focusVisible": {
    transition: "0.3s",
    color: theme.palette.mode === "dark" ? "#444444" : "#e2e2e2",
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgb(220,220,220,0.7)"
        : "rgb(0,30,60,0.7)",
  },
  [theme.breakpoints.up("xs")]: {
    right: "5%",
    backgroundColor: theme.palette.mode === "dark" ? "#DCDCDC" : "#001E3C",
  },
  [theme.breakpoints.up("lg")]: {
    right: "10%",
  },
}));

const ScrollToTopButton = ({ showBelow = 300 }) => {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <Box>
      {show && (
        <ScrollToTop onClick={handleClick} aria-label="to top" component="span">
          <ExpandLess />
        </ScrollToTop>
      )}
    </Box>
  );
};

export default ScrollToTopButton;
