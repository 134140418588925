import React, { useEffect, useState } from "react";
import { DeleteForeverOutlined, ThumbsUpDown } from "@mui/icons-material";
import {
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  AreYouSureModal,
  Banner,
  DataGridComponent,
  InputRowForm,
  MonthSelectField,
  ScrollToTopButton,
  StatsComponent,
} from "../components";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { format, formatISO } from "date-fns";
import statuses from "../config/statuses";
import renderProfit from "../utils/renderProfit";
import { Box } from "@mui/system";
import axios from "axios";
import useCustomSnackbar from "../hooks/useCustomSnackbar";
// import sortBets from "../utils/sortBets";
import Chart from "../components/ChartComponent/ChartComponent";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { API_URL } from "../config/config";
import { months } from "../config/months";
import update from "immutability-helper";
import bookies from "../config/bookies";

const Dashboard = (props) => {
  const { t } = useTranslation("common");
  //TODO handle autoUpdate
  const user = useAuthUser();
  const header = useAuthHeader();

  const snackbar = useCustomSnackbar();
  const [providers, setProviders] = useState([]);
  const [bets, setBets] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const token = header();
  const userId = user().id;
  const [bankSize, setBankSize] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [rowCount, setRowCount] = useState(0);
  const [lastBets, setLastBets] = useState(null);
  //make useeffect that listens to perPage change and page change ->

  useEffect(() => {
    try {
      let active = true;

      (async () => {
        setLoading(true);
        const bets = await axios.get(
          `${API_URL}/users/${userId}/bets?limit=${perPage}&page=${page}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (!active) {
          return;
        }
        console.log(bets.data[0]);
        setLastBets(bets?.data[0]?.lastBets);
        setBets(bets?.data[0]?.bets);
        setLoading(false);
      })();
    } catch (error) {
      snackbar.showError(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page]);

  const fetchInitialData = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `${API_URL}/users/${userId}/bets/stats/main?year=${new Date().getFullYear()}&month=${format(
        new Date(),
        "MMMM"
      )}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    data && setStats(data);
    bets && setRowCount(data?.totals?.count || 0);
    data && setProviders(data.user.providers);
    data && setBankSize(data.user.bankSize);
    setChartData(data.results);
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchInitialData();
    } catch (e) {
      snackbar.showError(e.message);
    }
    // eslint-disable-next-line
  }, []);

  const [monthsToRender, setMonthsToRender] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    months[new Date().getMonth()]
  );

  const fetchChartData = async (month) => {
    try {
      const { data: chartData } = await axios.get(
        `${API_URL}/users/${userId}/bets/daily?year=${new Date().getFullYear()}&month=${month}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return chartData;
    } catch (e) {
      snackbar.showError(e.message);
    }
  };

  const handleSetChartData = async (month) => {
    const chartData = await fetchChartData(month);
    chartData && setChartData(chartData);
    setSelectedMonth(month);
  };

  useEffect(() => {
    if (bets && bets[0]) {
      const lastMatch = new Date(bets[0].date);
      const firstMatch = new Date(new Date().getFullYear(), 0, 1);
      const mnths = [];
      const currentMonth = new Date().getMonth();
      for (
        let index = firstMatch.getMonth();
        index <= lastMatch.getMonth();
        index++
      ) {
        mnths.push(months[index]);
      }
      setMonthsToRender(mnths);
      bets.length > 0 && handleSetChartData(months[currentMonth]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bets]);

  const getStats = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/users/${userId}/bets/stats/main?year=${new Date().getFullYear()}&month=${format(
          new Date(),
          "MMMM"
        )}`,
        { headers: { Authorization: token } }
      );
      setStats(data);
      setProviders(data.user.providers);
    } catch (e) {
      snackbar.showError(e);
    }
  };

  const handleUpdateBet = async (e) => {
    try {
      setLoading(true);
      const {
        id: betId,
        field: fieldName,
        value: updateValue,
        row: { status, provider, stake },
      } = e;

      let formattedDate = null;
      if (fieldName === "date") {
        const date = formatISO(new Date(updateValue)).replace(
          /T.*/,
          "T00:00:00.000+00:00"
        );
        formattedDate = date;
      }
      const response = await axios.patch(
        `${API_URL}/users/${userId}/bets/${betId}`,
        {
          [fieldName]: formattedDate ?? updateValue,
          status,
          provider,
          oldStake: stake,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response && response.status === 200) {
        const index = bets.findIndex((bet) => bet._id === betId);
        const updatedBets = update(bets, {
          $splice: [[index, 1, response.data.bet]],
        });
        const providers = response.data.providers;
        providers && setProviders(providers);
        setBets(updatedBets);
        snackbar.showSuccess(t("betStatusSuccessfullyUpdated"));
        setLoading(false);
      }
    } catch (e) {
      let message = e.response.data.message || e.message;
      snackbar.showError(message);
    }
  };

  const handleUpdateBetStatus = async (
    betId,
    prevStatus,
    newStatus,
    provider,
    stake,
    odds,
    isSlip
  ) => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${API_URL}/users/${userId}/bets/${betId}/status`,
        {
          prevStatus,
          newStatus,
          stake,
          odds,
          provider,
          isSlip,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response && response.status === 200) {
        setProviders(response.data);
        await fetchUserData();
        snackbar.showSuccess(t("betStatusSuccessfullyUpdated"));
      }
      setLoading(false);
    } catch (e) {
      snackbar.showError(e);
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [betToBeDeleted, setBetToBeDeleted] = useState(null);

  const handleDeleteBet = async (e, rowId) => {
    e.stopPropagation();
    setIsDeleteModalOpen(false);
    const betId = rowId ? rowId : betToBeDeleted;
    try {
      const response = await axios.delete(
        `${API_URL}/users/${userId}/bets/${betId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response && response.status === 204) {
        snackbar.showSuccess(t("betSuccessfullyRemoved"));
        setBetToBeDeleted(null);
      }
      await fetchUserData();
    } catch (e) {
      snackbar.showError(e.message);
    }
  };

  const handleUpdateBetSlipStatus = async (e, bet, slip) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const response = await axios.patch(
        `${API_URL}/users/${userId}/bets/${slip._id}/${bet._id}`,
        {
          betStatus: e.target.value,
          provider: slip.provider,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response && response.status === 200) {
        snackbar.showSuccess(t("betStatusSuccessfullyUpdated"));
      }
      await fetchUserData();
    } catch (error) {
      snackbar.showError(e.message);
    }
  };

  const columns = [
    {
      field: "resultIcon",
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThumbsUpDown />
        </Box>
      ),
      width: 50,
      editable: false,
      renderCell: (params) => statuses[params.row.status.toLowerCase()].icon,
      sortable: false,
    },
    {
      field: "event",
      headerName: t("event"),
      width: 200,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const isSlip = params.row.slip.length > 1;
        return (
          <List
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mx: 0,
            }}
          >
            {params.row.slip.map((event) => (
              <ListItem
                key={event._id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 0,
                  py: 0.5,
                  px: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    mr: 2,
                  }}
                >
                  {event.eventName}
                </Typography>
                {isSlip && params.row.status === statuses.pending.status && (
                  <FormControl sx={{ mx: 1 }} variant="standard">
                    <Select
                      sx={{
                        minWidth: "50px",
                        alignSelf: "flex-end",
                      }}
                      SelectDisplayProps={{
                        style: {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                      value={event.status}
                      onChange={(e) =>
                        handleUpdateBetSlipStatus(e, event, params.row)
                      }
                      disabled={
                        !providers.some(
                          (provider) => provider._id === params.row.provider
                        )
                      }
                    >
                      {Object.keys(statuses).map((status) => (
                        <MenuItem
                          value={statuses[status].status}
                          key={statuses[status].status}
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {statuses[status].status ===
                            statuses.half_lost.status ||
                          statuses[status].status ===
                            statuses.half_won.status ? (
                            <Typography
                              sx={{
                                lineHeight: 0.2,
                                fontWeight: "bold",
                                fontSize: 10,
                                mr: 0.5,
                              }}
                            >
                              1/2
                            </Typography>
                          ) : (
                            ""
                          )}
                          {statuses[status].icon}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </ListItem>
            ))}
          </List>
        );
      },
    },
    {
      field: "prediction",
      headerName: t("prediction"),
      width: 80,
      editable: true,
      sortable: false,
      renderCell: (params) => (
        <List>
          {params.row.slip.map((event) => (
            <ListItem sx={{ py: 0.5, fontSize: 12 }} key={event._id}>
              {event.prediction}
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      field: "odds",
      headerName: t("odd"),
      type: "number",
      width: 150,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        const isSlip = params.row.slip.length > 1;

        return isSlip ? (
          <List sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "80px",
                minWidth: "30px",
              }}
            >
              {params.row.slip.map((event) => (
                <ListItem
                  style={{
                    padding: 1,
                    fontSize: 12,
                  }}
                  key={event._id}
                >
                  @ {event.odd}
                </ListItem>
              ))}
            </Box>
            <Divider orientation="vertical" flexItem sx={{ ml: 0.5 }} />
            <ListItem sx={{ display: "flex", textAlign: "center", px: 0.5 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                {t("total")}: {params.row.odds}
              </Typography>
            </ListItem>
          </List>
        ) : (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
              @ {params.row.odds}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "date",
      headerName: t("date"),
      type: "date",
      width: 100,
      editable: true,
      sortable: false,
      renderCell: (params) => format(Date.parse(params.row.date), "yyyy-MM-dd"),
    },
    {
      field: "stake",
      headerName: t("stake"),
      type: "number",
      width: 80,
      editable: true,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params.row.stake} {t("currency")}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: t("status"),
      width: 150,
      editable: false,
      sortable: false,
      renderCell: ({ field, row, id }) => {
        const isSlip = row.slip.length > 1;

        return (
          <FormControl sx={{ m: 1 }}>
            <Select
              sx={{ width: "120px", ml: 1, height: "30px" }}
              labelId="status-label"
              placeholder={t("status")}
              value={row.status}
              onChange={(e) =>
                handleUpdateBetStatus(
                  id,
                  row.status,
                  e.target.value,
                  row.provider,
                  row.stake,
                  row.odds,
                  row.slip.length > 1
                )
              }
              disabled={
                !providers.some((provider) => provider._id === row.provider)
              }
            >
              {Object.keys(statuses).map((status) => (
                <MenuItem
                  sx={{
                    display: `${
                      isSlip &&
                      statuses[status].status !== statuses.pending.status &&
                      row.status !== statuses.pending.status &&
                      "none"
                    }`,
                  }}
                  value={statuses[status].status}
                  key={statuses[status].status}
                  disabled={
                    isSlip &&
                    statuses[status].status === statuses.pending.status &&
                    row.status === statuses.pending.status
                  }
                >
                  {t(statuses[status].displayName)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "provider",
      headerName: t("bookmaker"),
      width: 120,
      editable: false,
      sortable: false,
      renderCell: ({ field, row, id }) => {
        const provider =
          providers.some((provider) => provider._id === row.provider) &&
          providers.filter((provider) => provider._id === row.provider);
        return (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              color: bookies[provider[0]?.providerName]?.color || "#77D4FC",
            }}
            id="provider"
          >
            {providers.some((provider) => provider._id === row.provider) ? (
              provider[0].providerName + " " + provider[0]?.subname
            ) : (
              <span color="#000000">deleted</span>
            )}
          </Typography>
          // <FormControl sx={{ m: 1, minWidth: 90 }}>
          //   <Select
          //     sx={{ height: "30px" }}
          //     labelId="provider-label"
          //     id="provider"
          //     defaultValue={
          //       providers.some((provider) => provider._id === row.provider)
          //         ? row.provider
          //         : "deleted"
          //     }
          //     onChange={(e) => handleUpdateBet(id, field, e.target.value)}
          //     disabled={
          //       !providers.some((provider) => provider._id === row.provider)
          //     }
          //   >
          //     <MenuItem value="deleted" disabled>
          //       {t("deleted")}
          //     </MenuItem>

          //     {providers.map((provider) => {
          //       return (
          //         <MenuItem key={provider._id} value={provider._id}>
          //           {provider.providerName}
          //         </MenuItem>
          //       );
          //     })}
          //   </Select>
          // </FormControl>
        );
      },
    },
    {
      field: "profit",
      headerName: t("profit"),
      width: 110,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const profit = renderProfit({
          stake: params.row.stake,
          odds: params.row.odds,
          status: params.row.status,
        });
        return (
          <Typography>
            {parseFloat(profit).toFixed(2)} {t("currency")}
          </Typography>
        );
      },
    },
    // {
    //   field: "edit",
    //   headerName: "",
    //   width: 60,
    //   editable: false,
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       console.log(params);
    //     };
    //     return (
    //       <IconButton color="primary" component="span" onClick={onClick}>
    //         <Edit color="primary" />
    //       </IconButton>
    //     );
    //   },
    // },
    {
      field: "delete",
      headerName: "",
      width: 60,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <AreYouSureModal
              open={isDeleteModalOpen}
              handleDelete={handleDeleteBet}
              handleClose={() => {
                setIsDeleteModalOpen(false);
              }}
              modalText={t("YouAreTryingToDeletePendingBet")}
            />
            <IconButton
              color="primary"
              component="span"
              onClick={(e) => {
                if (params.row.status === statuses.pending.status) {
                  setBetToBeDeleted(params.row._id);
                  setIsDeleteModalOpen(true);
                } else {
                  handleDeleteBet(e, params.row._id);
                }
              }}
            >
              <DeleteForeverOutlined color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  //more methods =>   watch,

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      slip: [
        {
          eventName: "",
          prediction: "",
          odd: "",
          date: format(new Date(), "yyyy-MM-dd"),
        },
      ],
      provider: "",
      status: statuses.pending.status,
      stake: "",
      odds: "",
    },
  });

  // more methods => prepend, swap, move, insert, replace
  const { fields, append, remove } = useFieldArray({
    control,
    name: "slip",
  });

  const handleAddBet = async (data) => {
    if (!data && data.slip.length <= 0) return;
    try {
      setLoading(true);
      const { slip } = data;
      const formattedSlip = slip.map(({ eventName, prediction, odd, date }) => {
        return {
          eventName,
          prediction,
          odd: parseFloat(odd),
          date: formatISO(Date.parse(date)),
        };
      });
      data.slip = formattedSlip;
      data.odds = formattedSlip.reduce(
        (prev, curr) => (prev * curr.odd).toFixed(2),
        1
      );

      //sets the day of the slip to the day of the latest event
      data.date = formattedSlip.sort(
        (slipA, slipB) => new Date(slipB.date) - new Date(slipA.date)
      )[0].date;

      //TODO autoUpdate toggle
      data.autoUpdate = user().autoUpdate;
      const response = await axios.post(
        `${API_URL}/users/${userId}/bets`,
        {
          slip: data.slip,
          date: data.date,
          odds: data.odds,
          stake: data.stake,
          prediction: data.prediction,
          autoUpdate: data.autoUpdate,
          status: data.status,
          provider: data.provider,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response && response.status === 201) {
        await fetchUserData();
        snackbar.showSuccess(t("betAddedSuccessfully"));
        reset();
        setValue("provider", "");
        setValue("status", statuses.pending.status);
      }
    } catch (e) {
      snackbar.showError("error adding bet");
    }
  };

  const fetchUserData = async () => {
    try {
      const bets = await axios.get(
        `${API_URL}/users/${userId}/bets?limit=${perPage}&page=${page}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      await getStats();
      setBets(bets.data[0]?.bets);
      setLoading(false);
    } catch (e) {
      snackbar.showError(e);
      setLoading(false);
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ paddingY: "80px" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ScrollToTopButton />
        {user().subscriptionStatus !== "active" && <Banner userData={user()} />}
        <StatsComponent
          stats={stats}
          initialProviders={providers}
          bankSizeInitial={bankSize}
          subscribed={
            user().subscriptionStatus === "active" ||
            user().subscriptionStatus === "trialing"
          }
        />

        {(user().subscriptionStatus === "active" ||
          user().subscriptionStatus === "trialing") && (
          <>
            <InputRowForm
              handleSubmit={handleSubmit(handleAddBet)}
              {...{
                control,
                register,
                fields,
                providers,
                errors,
                append,
                remove,
                setValue,
                bankSize,
              }}
            />
            <DataGridComponent
              bets={bets || []}
              columns={columns}
              page={page}
              pageSize={perPage}
              setPageSize={setPerPage}
              rowCount={rowCount}
              setPage={setPage}
              onCellEditCommit={handleUpdateBet}
              lastBets={lastBets}
              // loading={loading}
            />
            {selectedMonth && (
              <MonthSelectField
                selectedMonth={selectedMonth}
                setSelectedMonth={handleSetChartData}
                monthsToRender={monthsToRender}
              />
            )}
            <Chart chartData={chartData} />
          </>
        )}
      </Container>
    </div>
  );
};

export default Dashboard;
