import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

const MonthSelectField = ({
  selectedMonth,
  setSelectedMonth,
  monthsToRender,
}) => {
  const { t } = useTranslation("common");
  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ fontSize: 20, mb: 2, mt: 2 }}>{t("month")}</Typography>
      <FormControl fullWidth style={{ marginBottom: "1rem" }}>
        <Select
          labelId="month-label"
          onChange={(e) => setSelectedMonth(e.target.value)}
          fullWidth
          value={selectedMonth}
        >
          {monthsToRender.map((month) => (
            <MenuItem key={month} value={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MonthSelectField;
