import React from "react";
import { Box, Typography } from "@mui/material";

const PresentationalView = ({ provider, providerBalance, t }) => (
  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
    <Typography
      variant="h5"
      sx={{
        fontWeight: "400",
        marginTop: "-1rem",
        alignSelf: "flex-start",
        marginLeft: "1rem",
        fontSize: "1rem",
        color: (theme) => theme.palette.text.primary,
      }}
    >
      {provider.subname}
    </Typography>
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        color: (theme) =>
          parseInt(providerBalance) > 0
            ? theme.palette.success.main
            : theme.palette.error.main,
        fontSize: "1.5rem",
      }}
    >
      {providerBalance} {t("currency")}
    </Typography>
  </Box>
);

export default PresentationalView;
