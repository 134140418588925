import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import PillButton from "../components/PillButton/PillButton";
import useCustomSnackbar from "../hooks/useCustomSnackbar";
import { API_URL } from "../config/config";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

const Profile = () => {
  const { t } = useTranslation("common");
  const user = useAuthUser();
  const header = useAuthHeader();
  const token = header();
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [userName, setUserName] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        email: userEmail,
        username: userName,
      };
    }, [userEmail, userName]),
  });

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const handleUpdateUser = async (userData) => {
    try {
      setLoading(true);
      if (userData.confirmPassword) {
        delete userData.confirmPassword;
      }
      const updatedUser = await axios.patch(
        `${API_URL}/users/${user().id}`,
        userData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (updatedUser && updatedUser.status === 200) {
        snackbar.showSuccess(t("profileSuccessfullyUpdated"));
        setUserEmail(updatedUser.data.email);
        setUserName(updatedUser.data.username);
        setLoading(false);
      }
    } catch (e) {
      snackbar.showError(t("errorUpdatingProfile"));
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/users/${user().id}`, {
        headers: {
          Authorization: token,
        },
      });
      if (response && response.status === 200) {
        setUserEmail(response.data.email);
        setUserName(response.data.username);
        reset({
          email: response.data.email,
          username: response.data.username,
        });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const updateStats = async () => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${API_URL}/users/${user().id}/updateStats`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response && response.status === 200) {
        snackbar.showSuccess(t("statsUpdated"));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ paddingY: "80px" }}>
        <Typography sx={{ fontSize: 27, fontWeight: 500, mt: 2 }}>
          {t("profile")}{" "}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(handleUpdateUser)}
          noValidate
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          {/* {errorMsg && <Alert severity="error">{errorMsg}</Alert>} */}
          <TextField
            color="success"
            margin="normal"
            required
            id="username"
            label={t("username")}
            name="username"
            autoComplete="username"
            autoFocus
            {...register("username")}
            error={!!errors.username}
            helperText={errors.username && t("usernameRequired")}
            focused
          />
          <TextField
            color="success"
            margin="normal"
            required
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            {...register("email", {
              pattern: /^\S+@\S+\.\S+$/,
            })}
            error={!!errors.email}
            helperText={errors.email && t("emailRequired")}
            focused
          />
          <TextField
            color="success"
            margin="normal"
            required
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password", { required: true, minLength: 6 })}
            error={!!errors.password}
            helperText={errors.password && t("passwordRequired")}
          />
          <TextField
            margin="normal"
            color="success"
            required
            name="confirmPassword"
            label={t("confirmPassword")}
            type="password"
            id="confirmPassword"
            {...register("confirmPassword", {
              minLength: 6,
              validate: (pass) => {
                if (watch("password") !== pass) return t("passwordsDontMatch");
              },
            })}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword && t("passwordsDontMatch")}
          />
          <PillButton
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 8, width: "50%", alignSelf: "center" }}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{
                  my: 0.5,
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "#ccc" : "#444",
                }}
              />
            )}
            {!loading && t("save")}
          </PillButton>
        </Box>
        <Box>
          <PillButton onClick={updateStats}>Update stats</PillButton>
        </Box>
      </Container>
    </div>
  );
};

export async function getServerSideProps(ctx) {}

export default Profile;
