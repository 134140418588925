import {
  Alert,
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { LockOutlined } from "@mui/icons-material";
import PillButton from "../components/PillButton/PillButton";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import axios from "axios";
import { API_URL } from "../config/config";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated()) {
    return <Navigate to="/dashboard" />;
  }

  const login = async (credentials) => {
    setLoading(true);
    try {
      // eslint-disable-next-line
      const { email, password, rememberMe } = credentials;
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password,
        // rememberMe,
      });

      if (response && response.status === 200) {
        if (
          signIn({
            token: response.data.tokens.access.token,
            tokenType: "Bearer",
            expiresIn: 30,
            refreshToken: response.data.tokens.refresh.token,
            refreshTokenExpireIn: 43200,
            authState: response.data.user,
          })
        ) {
          navigate("/dashboard");
        }
      }
    } catch (e) {
      setLoading(false);
      if (e.response?.data?.code === 401)
        setErrorMsg(t("invalidEmailOrPassword"));
      if (e.response?.data?.code === 403)
        setErrorMsg(
          <Trans i18nKey="pleaseVerifyEmail">
            Unsuccessful login. Please verify your account
            <Link href="/resend-verification-email"> here</Link>.
          </Trans>
        );
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "info.dark" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("signIn")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(login)}
            noValidate
            sx={{ mt: 1 }}
          >
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <TextField
              color="success"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("email")}
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+\.\S+$/,
              })}
              error={!!errors.email}
              helperText={errors.email && t("emailRequired")}
            />
            <TextField
              color="success"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("password")}
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password", { required: true, minLength: 6 })}
              error={!!errors.password}
              helperText={errors.password && t("passwordRequired")}
            />
            <FormControlLabel
              control={<Checkbox color="success" {...register("rememberMe")} />}
              label={t("rememberMe")}
            />
            <PillButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    my: 0.5,
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#ccc" : "#444",
                  }}
                />
              )}
              {!loading && t("signIn")}
            </PillButton>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2" color="#2e7d32">
                  {t("forgotPassword")}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2" color="#2e7d32">
                  {t("register")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
