import { Box, Link, Typography } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import { useTranslation } from "react-i18next";
import React from "react";

const Banner = ({ userData }) => {
  const { t } = useTranslation("common");

  return (
    <Box
      sx={{
        py: 2,
        backgroundColor:
          userData.subscriptionStatus !== "active" &&
          userData.subscriptionStatus !== "trialing"
            ? "rgba(245, 77, 64, 0.2)"
            : " rgba(36, 121, 9, 0.3)",
        borderRadius: "8px",
        px: 4,
        mb: 4,
        textAlign: "center",
      }}
    >
      {userData.trialActive && (
        <Box>
          <Typography sx={{ fontSize: 21, fontWeight: 500 }}>
            {t("youAreCurrentlyRunningOnATrialPeriod")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ pr: 2, my: 1 }}>
              {t("daysLeft")}{" "}
              {differenceInDays(new Date(userData.trialEnd), new Date())}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 200 }}>
              {t("expiresOn")}{" "}
              {format(new Date(userData.trialEnd), "dd-MM-yyyy")}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 300 }}>
            {t("toContinueOrUpgradeSubscriptionFollowLinkToAddPaymentMethod")}
            <Link href="subscription">{t("subscribeMe")}</Link>
          </Typography>
        </Box>
      )}
      {userData.subscriptionStatus !== "active" &&
        userData.subscriptionStatus !== "trialing" && (
          <Box>
            <Typography sx={{ fontSize: 21, fontWeight: 500 }}>
              {t("youDontHaveAnActiveSubscription")}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 300 }}>
              {t("toSubscribeFollowLink")}
              <Link href="subscription">{t("subscribeMe")}</Link>
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default Banner;
