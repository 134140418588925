import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { useEffect, useState } from "react";
import { AuthProvider, createRefresh } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import {
  ArbitrageCalculator,
  Dashboard,
  ForgotPassword,
  Home,
  Login,
  Profile,
  Register,
  ResendVerificationEmail,
  Subscription,
  VerifyEmail,
} from "./pages";
import RequiredAuth from "./wrappers/RequiredAuth";
import { CustomSnackbar } from "./components";
import axios from "axios";
import { API_URL } from "./config/config";

function App() {
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState("bg");

  const setLanguage = (e) => {
    i18n.changeLanguage(e.target.id);
    handleLocale(e.target.id);
  };

  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const light = {
    palette: {
      mode: "light",
      background: { default: "#F2F2F2" },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
    },
  };

  const dark = {
    palette: {
      mode: "dark",
      background: { default: "#282C34" },
      primary: {
        main: "#7EA5FF",
      },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
    },
  };

  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    !isDarkTheme
      ? localStorage.setItem("theme", "dark")
      : localStorage.setItem("theme", "light");
  };

  const handleLocale = (locale) => {
    setLocale(locale);
    localStorage.setItem("locale", locale);
  };

  useEffect(() => {
    const locale = localStorage.getItem("locale");
    const theme = localStorage.getItem("theme");
    locale && handleLocale(locale);
    theme === "light" ? setIsDarkTheme(false) : setIsDarkTheme(true);
    // eslint-disable-next-line
  }, []);

  const refreshApi = createRefresh({
    interval: 29,
    refreshApiCallback: ({
      authToken,
      authTokenExpireAt,
      refreshToken,
      refreshTokenExpiresAt,
      authUserState,
    }) => {
      return axios
        .post(
          `${API_URL}/auth/refresh-tokens`,
          {
            refreshToken,
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then(({ data }) => {
          return {
            isSuccess: true,
            newAuthToken: data.access.token,
            newAuthTokenExpireIn: 30,
            newRefreshToken: data.refresh.token,
            newRefreshTokenExpiresIn: 48200,
          };
        })
        .catch((e) => {
          console.log(e);
          return {
            isSuccess: false, // For unsuccessful network request isSuccess is false
          };
        });
    },
  });

  return (
    <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
      <CssBaseline />
      <AuthProvider
        authType="cookie"
        authName="_auth"
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
        refresh={refreshApi}
      >
        <SnackbarProvider
          SnackbarProps={{ autoHideDuration: 4000 }}
          SnackbarComponent={CustomSnackbar}
        >
          <Router>
            <Routes>
              <Route
                element={
                  <MainLayout
                    locale={locale}
                    setLocale={setLanguage}
                    setTheme={changeTheme}
                    isDarkTheme={isDarkTheme}
                  />
                }
              >
                <Route element={<Home />} path="/" />
                <Route element={<Login />} path="/login" />
                <Route element={<Register />} path="/register" />
                <Route
                  element={<ResendVerificationEmail />}
                  path="/resend-verification-email"
                />
                <Route element={<ForgotPassword />} path="/forgot-password" />
                <Route element={<VerifyEmail />} path="/verify-email" />
                <Route
                  element={
                    <RequiredAuth>
                      <Dashboard />
                    </RequiredAuth>
                  }
                  path="/dashboard"
                />
                <Route
                  element={
                    <RequiredAuth>
                      <Subscription />
                    </RequiredAuth>
                  }
                  path="/subscription"
                />
                <Route
                  element={
                    <RequiredAuth>
                      <Profile />
                    </RequiredAuth>
                  }
                  path="/profile"
                />
                <Route
                  element={
                    <RequiredAuth>
                      <ArbitrageCalculator />
                    </RequiredAuth>
                  }
                  path="/arbitrage-calculator"
                />
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
