export const bookies = {
  efbet: {
    color: "#FFCB04",
    name: "efbet",
  },
  bet365: {
    color: "#126E51",
    name: "bet365",
  },
  betano: {
    color: "#FF6600",
    name: "BETANO",
  },
  betway: {
    color: "#00A826",
    name: "betWay",
  },
  bwin: {
    color: "#FFD300",
    name: "bwin",
  },
  palmsbet: {
    color: "#3795FE",
    name: "PalmsBet",
  },
  sesame: {
    color: "#644091",
    name: "SESAME",
  },
  sportingwin: {
    color: "#0D89D3",
    name: "sportingwin",
  },
  winbet: {
    color: "#E42313",
    name: "WINBET",
  },
  inbet: {
    color: "#0054A5",
    name: "inbet",
  },
  8888: {
    color: "#00FFFE",
    name: "8888",
  },
  alphawin: {
    color: "#FF6600",
    name: "alphawin",
  },
  pinnacle: {
    color: "#FF5500",
    name: "PINNACLE",
  },
  betinasia: {
    color: "#DA2128",
    name: "BetInAsia",
  },
  "22bet": {
    color: "##054146",
    name: "22bet",
  },
};

export const tierLimit = {
  BEGINNER: 1,
  ADVANCED: 3,
  PRO: 10,
};

export default bookies;
