import React from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { CheckCircle, DeleteForeverOutlined } from "@mui/icons-material";
import bookies from "../../config/bookies";
import AreYouSureModal from "../AreYouSureModal";

const EditModeView = ({
  provider,
  providerBalance,
  setProviderBalance,
  providerSubname,
  setProviderSubname,
  handleUpdateProvider,
  handleDeleteProvider,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  t,
}) => (
  <FormControl>
    <Box
      sx={{
        mt: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <TextField
        id={`${provider._id}-balance`}
        name={`${provider.providerName}-balance`}
        type="number"
        sx={{
          width: "72%",
          textAlign: "right",
          mb: 1.5,
          "& input": {
            color: (theme) => theme.palette.mode === "light" && "#fff",
          },
          "& label.Mui-focused": {
            color: bookies[provider.providerName]?.color || "#77D4FC",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: bookies[provider.providerName]?.color || "#77D4FC",
            },
            "&.Mui-focused fieldset": {
              borderColor: bookies[provider.providerName]?.color || "#77D4FC",
            },
          },
          "& .MuiInputAdornment-root p": {
            color: (theme) => theme.palette.mode === "light" && "#e2e2e2",
          },
        }}
        size="small"
        label={`${provider.providerName} Balance`}
        focused
        onChange={(e) => setProviderBalance(e.target.value)}
        value={providerBalance}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{t("currency")}</InputAdornment>
          ),
        }}
      />
      <TextField
        id={`${provider._id}-subname`}
        name={`${provider.providerName}-subname`}
        type="text"
        sx={{
          width: "72%",
          textAlign: "right",
          "& input": {
            color: (theme) => theme.palette.mode === "light" && "#fff",
          },
          "& label.Mui-focused": {
            color: bookies[provider.providerName]?.color || "#77D4FC",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: bookies[provider.providerName]?.color || "#77D4FC",
            },
            "&.Mui-focused fieldset": {
              borderColor: bookies[provider.providerName]?.color || "#77D4FC",
            },
          },
        }}
        size="small"
        label={`${provider.providerName} Subname`}
        focused
        onChange={(e) => setProviderSubname(e.target.value)}
        value={providerSubname}
      />
      <Box>
        <IconButton
          color="primary"
          component="span"
          onClick={() => handleUpdateProvider(provider)}
          disableTouchRipple
          sx={{
            p: "0.25",
          }}
        >
          <CheckCircle
            sx={{
              color: bookies[provider.providerName]?.color || "#77D4FC",
            }}
          />
        </IconButton>
        <IconButton
          sx={{
            p: "0.25",
          }}
          color="primary"
          component="span"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <DeleteForeverOutlined
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.error.main,
              },
              color: "#888",
            }}
          />
        </IconButton>
      </Box>
      <AreYouSureModal
        open={isDeleteModalOpen}
        handleDelete={() => {
          handleDeleteProvider(provider._id);
          setIsDeleteModalOpen(false);
        }}
        handleClose={() => {
          setIsDeleteModalOpen(false);
        }}
        modalText={t("AreYouSureDeleteProvider")}
      />
    </Box>
  </FormControl>
);

export default EditModeView;
