import { StarOutline } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  GlobalStyles,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PillButton from "../components/PillButton/PillButton";
import background from "../assets/images/background.jpg";

const Home = () => {
  const { t } = useTranslation("common");

  const tiers = [
    {
      title: t("beginner"),
      price: "9.95",
      description: [
        t("oneBookie"),
        t("currentMonthStatistics"),
        t("profitTracker"),
        t("freeTrial"),
      ],
      buttonText: t("freeSignUp"),
      buttonVariant: "outlined",
    },
    {
      title: t("advanced"),
      subheader: t("mostPopular"),
      price: "14.95",
      description: [
        t("upToThreeBookies"),
        t("upToThreeMonthsStatistics"),
        t("profitTracker"),
        t("freeTrial"),
        t("support"),
      ],
      buttonText: t("freeSignUp"),
      buttonVariant: "contained",
    },
    {
      title: t("pro"),
      price: "18.95",
      description: [
        t("upToFiveBookies"),
        t("lifeTimeStatistics"),
        t("profitTracker"),
        t("freeTrial"),
        t("support"),
      ],
      buttonText: t("freeSignUp"),
      buttonVariant: "outlined",
    },
  ];

  return (
    <Box>
      <main>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            zIndex: -10,
            position: "absolute",
            top: 0,
          }}
        >
          <img
            src={background}
            style={{ zIndex: -10, height: "100vh", width: "100%" }}
            alt="background"
          />
        </Box>
        <GlobalStyles
          styles={{
            ul: { margin: 0, padding: 0, listStyle: "none" },
          }}
        />
        <CssBaseline />
        <Container
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "rgba(121,127,121,0.8)"
                : "rgba(121,127,121,0.8)",
            borderRadius: 4,
            pb: 6,
            mt: 5,
          }}
        >
          {/* Hero unit */}
          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{
              pt: 8,
              pb: 6,
              zIndex: 99,
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              {t("pricing")}
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              component="p"
            >
              {t("subscriptionDescription")}
            </Typography>
          </Container>
          {/* End hero unit */}
          <Container maxWidth="md" component="main">
            <Grid container spacing={4} alignItems="flex-end">
              {tiers.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === t("pro") ? 12 : 6}
                  md={4}
                >
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: "center" }}
                      action={
                        tier.title === t("advanced") ? <StarOutline /> : null
                      }
                      subheaderTypographyProps={{
                        align: "center",
                      }}
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[400]
                            : theme.palette.grey[800],
                      }}
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "baseline",
                          mb: 2,
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h4"
                          color="text.primary"
                        >
                          {tier.price + t("lev")}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          /{t("monthly")}
                        </Typography>
                      </Box>
                      <ul>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Link
                        href={`/register?tier=${tier.title.toLowerCase()}`}
                        sx={{ textDecoration: "none" }}
                      >
                        <PillButton fullWidth variant={tier.buttonVariant}>
                          {tier.buttonText}
                        </PillButton>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Container>
      </main>
    </Box>
  );
};

export default Home;
