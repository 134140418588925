import { Box, CircularProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import React from "react";
import statuses from "../../config/statuses";

const DataGridComponent = ({
  loading,
  bets,
  columns,
  pageSize,
  setPageSize,
  rowCount,
  setPage,
  page,
  onCellEditCommit,
  lastBets,
}) => {
  const { t } = useTranslation("common");
  return (
    <Box sx={{ mt: 5, width: "100%" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Typography
            variant="h5"
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark" ? "#F2F2F2" : "#444",
            }}
          >
            {t("bets")}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <DataGrid
              rows={bets}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 30]}
              getRowId={(row) => row._id}
              disableSelectionOnClick
              onCellEditCommit={onCellEditCommit}
              className="DataGrid"
              autoHeight
              getRowHeight={() => "auto"}
              onPageSizeChange={setPageSize}
              rowCount={rowCount}
              onPageChange={setPage}
              paginationMode="server"
              loading={loading}
              page={page}
              isCellEditable={(params) =>
                params.row.status === statuses.pending.status
              }
              getRowClassName={(params) =>
                lastBets && lastBets.some((bet) => bet._id === params.row._id)
                  ? "lastBet"
                  : ""
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DataGridComponent;
