import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  ComposedChart,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  CartesianGrid,
  Cell,
} from "recharts";

const TooltipContent = ({ active, payload, label }) => {
  const { t } = useTranslation("common");
  if (active) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #999",
          margin: 0,
          padding: 10,
          color: "#000",
        }}
      >
        <p className="intro">{`${t("profit")}: ${payload[0].payload.profit} ${t(
          "currency"
        )}`}</p>
        <p className="intro">
          {`${t("percentage")}:`}{" "}
          <span
            style={{
              fontWeight: "bold",
              color: payload[0].payload.percentage > 0 ? "#6FC9FF" : "#d35874",
            }}
          >
            {`${payload[0].payload.percentage}%`}{" "}
          </span>
        </p>
        <p className="label">{`${t("toDate")} : ${
          payload[0].payload.toDate
        } ${t("currency")}`}</p>
        {payload[0].payload.inPlayCount > 0 && (
          <p>{`${t("inPlayCount")}: ${payload[0].payload.inPlayCount}`}</p>
        )}
        {payload[0].payload.inPlayTotal > 0 && (
          <p>{`${t("inPlayTotal")}: ${payload[0].payload.inPlayTotal} ${t(
            "currency"
          )}`}</p>
        )}
      </div>
    );
  }

  return null;
};

const renderDot = (dot) => {
  const fill =
    dot?.payload?.profit < 0 ? "rgb(255, 99, 132)" : "rgb(111, 201, 255)";
  return (
    <svg
      x={dot.cx - 10}
      y={dot.cy - 10}
      width={20}
      height={20}
      fill={fill}
      stroke="#FFF"
      strokeWidth={0}
      key={Math.random()}
    >
      {dot.payload?.inPlayCount > 0 && (
        <circle cx="10" cy="10" r="6" fill="#FFB94B" />
      )}
      <circle cx="10" cy="10" r="3" fill={fill} />
    </svg>
  );
};

const ChartComponent = ({ chartData }) => {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {chartData?.length > 0 ? (
        <ComposedChart
          width={1000}
          height={600}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            bottom: 20,
            left: 20,
          }}
          key={Math.random()}
        >
          <CartesianGrid
            stroke="#e2e2e2"
            strokeWidth={0.5}
            strokeDasharray="5 4"
            strokeOpacity={0.4}
          />
          <XAxis
            dataKey="date"
            scale="point"
            domain={["dataMin - 1", "dataMax"]}
            padding={{ left: 27 }}
          />
          <YAxis
            yAxisId="profitAxis"
            domain={["dataMin - 100", "dataMax + 200"]}
            scale="linear"
          />
          <YAxis
            yAxisId="toDateAxis"
            orientation="right"
            stroke="#000"
            scale="linear"
            domain={["dataMin - 100", "dataMax + 200"]}
          />
          <Tooltip
            cursor={false}
            labelFormatter={() => ""}
            content={<TooltipContent />}
          />
          <Legend
            verticalAlign="top"
            height={50}
            formatter={(value, entry) => {
              return <span style={{ color: "#e2e2e2" }}>{value}</span>;
            }}
          />
          <Bar
            dataKey="profit"
            data={chartData}
            barSize={30}
            fill="#222"
            yAxisId="profitAxis"
            name={t("dailyProfit")}
            label={{ fill: "transparent", fontSize: 12, fontWeight: 400 }}
            unit={t("currency")}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`barCell-${index}`}
                stroke={
                  parseFloat(entry.profit) < 0
                    ? "rgb(255, 99, 132)"
                    : "rgb(111, 201, 255)"
                }
              />
            ))}
          </Bar>
          <Line
            type="monotone"
            dataKey="toDate"
            stroke="#111"
            strokeWidth={4}
            yAxisId="toDateAxis"
            activeDot={{ fill: "black", stroke: "black" }}
            dot={(dot) => renderDot(dot)}
            name={t("toDate")}
            unit={t("currency")}
          />
        </ComposedChart>
      ) : (
        <Typography
          sx={{
            mt: 10,
            px: 50,
            py: 5,
            border: "0.5px dashed #777",
            borderRadius: 4,
          }}
        >
          {t("noBetDataForThisMonth")}
        </Typography>
      )}
    </Box>
  );
};

export default ChartComponent;
