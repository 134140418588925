import styled from "@emotion/styled";
import { Button } from "@mui/material";

const PillButton = styled(Button)(({ theme, variant }) => ({
  boxShadow: "5px",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 20px",
  border: "none",
  lineHeight: 1.5,
  backgroundColor: theme.palette.mode === "light" ? "#1d5000" : "#247909",
  borderRadius: 50,
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" ? "#247909" : "#1d5000",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,180,0,.5)",
  },
  color: "#f2f2f2",
  ...(variant === "outlined" && {
    backgroundColor: "",
    textTransform: "none",
    color: theme.palette.mode === "light" ? "#444" : "#f2f2f2",
    border:
      theme.palette.mode === "light"
        ? "1px solid #1d5000"
        : "1px solid #247909",
    "&:hover": {
      backgroundColor: theme.palette.mode === "light" ? "#247909" : "#1d5000",
      border:
        theme.palette.mode === "light"
          ? "1px solid #247909"
          : "1px solid #1d5000",
      boxShadow: "none",
      color: "#f2f2f2",
    },
  }),
}));

export default PillButton;
